
.animated-image-image {
    width: 100%;
    object-fit: cover;
    height: calc(85vh - 236px);
    margin: calc(20px + 12.5vh) 0 calc(128px + 2.5vh) !important;
}


.animated-image-container {
    position: relative;
    transform-origin: bottom center;
    transform: scale(0);
}

.animated-image-container.fade-img-in {
    opacity: 1;
    transform: scale(1);
    animation: fade-in 1s ease-in-out;
}

.animated-image-container.fade-img-out {
    opacity: 0;
    transform: scale(0);
    animation: fade-out 1s ease-in-out;
}

@keyframes fade-in {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes fade-out {
    10% {
        opacity: 1;
    }
    100% {
        opacity: 0.05;
    }
}

.animated-image-text {
    font-family: 'Druk Wide Web';
    color: hsla(0, 0%, 95%, .01);
    text-align: right;
    text-transform: uppercase;
    font-weight: 900;
    font-style: normal;
    font-stretch: normal;
    font-size: 6.5vw;
    letter-spacing: .02em;
    line-height: .9em;
    max-width: 1500px;
    transform: translateX(70px);
    position: absolute;
    top: 10%;
    right: 3%;
    opacity: 0;
    transition: opacity 0.5s ease, transform 0.5s ease;
}


.animated-image-text.slide-right-in {
    opacity: 1;
    transform: translateX(0);
    animation: slide-right-in 1s ease-in-out;

}

.animated-image-text.slide-right-out {
    opacity: 0;
    transform: translateX(70px);
    animation: slide-right-out 1s ease-in-out;
}

@keyframes slide-right-in {
    0% {
        opacity: 0;
        transform: translateX(70px);
    }
    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes slide-right-out {
    0% {
        opacity: 1;
        transform: translateX(0);

    }
    100% {
        opacity: 0;
        transform: translateX(70px);
    }
}

@media screen and (max-width: 768px) {
    .animated-image-text {
        top: 15%
    }

    .animated-image-image {
        margin: 0 !important;
    }
}