
.test {
  margin: 2em;
  padding: 0.5em;
  border: 2px solid #000;
  text-align: center;
}

.scene {
  width: 100%;
  height: 100%;
  border: none;
  /* margin: 80px; */
  transform: scale(1);
}

.cube {
  width: 100vw;
  height: 100vh;
  position: relative;
  transform-style: preserve-3d;
  transform: translateZ(-100vh);
  /* transition: all 1.3s cubic-bezier(0.34, 1.56, 0.64, 1) 0.5s; */
}

.cube.show-front  { transform: translateZ(-100vh) rotateY(   0deg); }
.cube.show-right  { transform: translateZ(-100vh) rotateY( -90deg); }
.cube.show-back   { transform: translateZ(-100vh) rotateY(-180deg); }
.cube.show-left   { transform: translateZ(-100vh) rotateY(  90deg); }
.cube.show-top    { transform: translateZ(-100vh) rotateX( -90deg); }
.cube.show-bottom { transform: translateZ(-100vh) rotateX(  90deg); }

/* .cube.show-top .cube__face--left { width: 10vh; }
.cube.show-top .cube__face--right { width: 10vh; }

.cube.show-bottom .cube__face--left { width: 10vh; }
.cube.show-bottom .cube__face--right { width: 10vh; }  */

.bgImage {
  width: 100%;
  height: 100%;
}

.ctext {
  transform: translateZ(200px);
}

.cube__face {
  position: absolute;
  width: 100vw;
  height: 100vh;
  /* border: 2px solid black; */
  line-height: 200px;
  color: white;
  text-align: center;
  /* transition: all 1s 0.5s; */
}

.cube__face--top,.cube__face--bottom {
  height: 100vw
}

.cube.show-top > .cube__face--top {
  /* transition: height 1s 2s; */
  height: 100%;
}

.cube.show-bottom > .cube__face--bottom  {
  /* transition: height 1s 2s; */
  height: 100%;
}

/* .cube__face--front  { background: hsla(  0, 100%, 50%, 1); } */
.cube__face--front  {
  /* background: url('home-bg.jpg'); background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  filter: blur(5px);
  -webkit-filter: blur(5px); */
}

.cube__face--front  { transform: rotateY(  0deg) translateZ(50vw); }
.cube__face--right  { transform: rotateY( 90deg) translateZ(50vw); }
.cube__face--back   { transform: rotateY(180deg) translateZ(50vw); }
.cube__face--left   { transform: rotateY(-90deg) translateZ(50vw); }
.cube__face--top    { transform: rotateX( 90deg) translateZ(50vh); }
.cube__face--bottom { transform: rotateX(270deg) translateZ(calc(100vh - 50vw)); }
.cube__text { border: none; transform: rotateY(  0deg) translateZ(60vw); }
label { margin-right: 10px; }

@keyframes zoomOut {
  0% {transform: scale(1); perspective: 100000px;}
  25% {transform: scale(0.85); perspective: 5000px;}
  75% {transform: scale(0.85); perspective: 5000px;}
  100% {transform: scale(1); perspective: 100000px;}
}