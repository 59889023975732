.animated-image-slider {
    width: 60%;
    margin: 0 auto;
    display: flex
}

.animated-image-slider img {
    width: 87%;
}

.animated-image-slider video {
    width: 87%;
}

.animated-image-slider-text {
    margin-left: 20px;
    transform-origin: left top;
    transition: opacity 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.25s, transform 0.5s 0.25s;
}

.animated-image-slider-text.page-in {
    transform: rotate3d(0, 1, 0, 0deg) translateZ(0);
}

.animated-image-slider-text.page-out {
    transform: rotate3d(0, 1, 0, 90deg) translateZ(0);
}

.animated-image-slider-text h6 {
    font-weight: bold;
    font-size: 20px;
}

.animated-image-slider-text p {
    line-height: 1.5;
}

.animated-image-slider-button-container {
    display: grid;
    width: 100%;
    grid-template-columns: 1fr 1fr 1fr;
}

.animated-image-slider-button-container :not(:nth-child(2))  {
    cursor: pointer;
}

.animated-image-slider-button-container :last-child {
    text-align: center;
}

.animated-image-slider-button-container p{
    margin: 0 0 10px 0;
}

.swiper-pagination-bullet {
    background: white !important;
}

@media screen and (max-width: 768px) {
    .animated-image-slider {
        width: 90%;
        flex-direction: column;
    }

    .animated-image-slider img {
        width: 100%;
        object-fit: contain;
    }

    .animated-image-slider video {
        width: 100%;
    }

    .animated-image-slider-text {
        margin: 4% 0;
        text-align: start;
    }

}