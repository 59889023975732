.math-masr-details-main-container {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    position: relative;
    color: white;
}

.math-masr-details-nav-li {
    list-style-type: none;
}

.math-masr-details-nav {
    position: fixed;
    left: 0;
    top: 4%;
    z-index: 800;
    color: white;
}

@media screen and (max-width: 768px) {
    .math-masr-details-nav {
        display: none;
    }
}

.math-masr-details-nav-button {
    background: transparent;
    border: none;
    cursor: pointer;
    transform: rotateY(0deg) translateZ(0);
    font-weight: bold;
    transform-origin: left center;
    transition: all .25s cubic-bezier(.175, .885, .32, 1.275);
    color: white;
}

.math-masr-details-nav-button:hover {
    transform: rotateY(25deg) translateZ(0);
}

.math-masr-details-nav-button span {
    font-size: 12px;
    margin-right: 10px;
    font-weight: normal;
}

.math-masr-details-next-project-h2 {
    font-family: Druk Wide Web;
    font-style: normal;
    font-stretch: normal;
    font-weight: 500;
    font-size: 30px;
}

.math-masr-details-next-project {
    width: 60%;
    margin: 4% auto 10% auto;
    text-align: end;
    color: #fff;
    transition: color .2s ease-in-out;
}

.math-masr-details-next-project-span {
    font-family: Druk Wide Web;
    font-style: normal;
    font-stretch: normal;
    display: block;
    text-align: right;
    text-transform: uppercase;
    font-weight: 900;
    font-size: 6vw;
    letter-spacing: .02em;
    line-height: .9em;
    cursor: pointer;
    color: transparent;
    -webkit-text-stroke: 2px #fff;
    text-stroke: 2px #fff;
    transform: rotateY(-45deg) translateZ(0);
    transform-origin: right center;
    transition: color .2s ease-in-out, transform .5s;
}

.math-masr-details-next-project :hover {
    color: #fff;
    transform: rotateY(0) translateZ(0);
}

@media screen and (min-width: 768px) {
    .math-masr-details-next-project-span {
        transform: rotateY(-35deg) translateZ(0);
        transform-origin: right center;
        transition: color .2s ease-in-out, transform .5s;
        perspective: 100vw;
    }
}

@media screen and (max-width: 768px) {
    .math-masr-details-next-project-span {
        transform: rotateY(0) translateZ(0);
        transform-origin: right center;
        transition: color .2s ease-in-out, transform .5s;
    }

    .math-masr-details-next-project {
        width: 70%;
    }

    .animated-image-slider-button-container {
        text-align: center;
    }
}

@media screen and (min-width: 1441px) {
    .math-masr-details-next-project-span {
        font-size: 100px;
    }
}