.detailed-card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    border-radius: 6px;
    margin: 0 19%;
    padding: 24px;
    transform: scale(0);
    opacity: 0;
    transition: opacity 0.1s ease, transform 0.1s ease;
}

.detailed-card.scale-in {
    animation: scale-fade-in 0.4s ease-in-out;
    transform: scale(1);
    opacity: 1;
}

.detailed-card.scale-out {
    display: none;
}

@keyframes scale-fade-in {
    0% {
        opacity: 0;
        transform: scale(0);
    }
    100% {
        opacity: 1;
        transform: scale(1);
    }
}

.detailed-card-header {
    display: grid;
    align-items: center;
    margin-bottom: 5px;
    grid-template-columns: 1fr 1fr 1fr;
}

.detailed-card-date {
    margin: 0;
    text-align: center;
}
.d-none{
    display: none;
}
.detailed-card-title {
    font-size: 24px;
    margin: 0;
}
.detailed-card-title > a{
    color: black;
}

.detailed-card-body {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 24px 0;
}

hr.solid {
    border-top: 1px solid #bbb;
    margin: 5px 0;
}

.detailed-card-paragraph {
    font-size: 16px;
    line-height: 1.5;
    margin: 0;
    text-align: center;
}


@keyframes text-animation {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.detailed-card-footer {
    display: flex;
    align-items: center;
    margin-top: 1%;
}

.detailed-card-footer h2 {
    margin: 0;
}

.detailed-card-icon-list {
    display: flex;
    justify-content: space-between;
    margin: 0;
    padding: 0;
    list-style: none;
}

.detailed-card-icon-list2 {
    display: flex;
    margin: 0;
    padding: 0;
    list-style: none;
}

.detailed-card-icon-li {
    margin: 0 3px;
}

.detailed-card-icon-li a {
    height: 20px;
    margin-left: 15px;
    margin-bottom: 0;
    color: var(--secondary);
    cursor: pointer;
    display: flex;
}

.detailed-card-icon-list li {
    margin-left: 8px;
    margin-bottom: 0;
    font-size: 24px;
    cursor: pointer;
}

.detailed-card-paragraph.animated {
    animation: text-animation 1s ease-in-out;
    animation-fill-mode: forwards;
}

.detailed-card-title.animated {
    animation: text-animation 1s ease-in-out;
    animation-fill-mode: forwards;
}

.link.animated {
    animation: text-animation 1s ease-in-out;
    animation-fill-mode: forwards;
}

.detailed-card-icon-list2.animated {
    animation: text-animation 1s ease-in-out;
    animation-fill-mode: forwards;
}

.detailed-card-icon-list.animated {
    animation: text-animation 1s ease-in-out;
    animation-fill-mode: forwards;
}

.moreBtn {
    border: none;
    background: transparent;
}

@media screen and (max-width: 768px) {
    .detailed-card-header {
        gap: 5px;
        font-size: 15px;
        grid-template-columns: 1fr;
    }

    .detailed-card {
        margin: 0 5% 5% 5%;
    }

    .link--thebe {
        justify-self: center;
    }

    .detailed-card-title {
        justify-self: center;
    }

    .detailed-card-footer {
        flex-direction: column;
    }

    .detailed-card-footer h2 {
        font-size: 20px;
        margin-bottom: 3%;
        justify-self: center;
    }

    .detailed-card-footer ul {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
        gap: 5px 0;
        margin-bottom: 4%;
        justify-content: center;
        justify-self: center;
    }

    .detailed-card-header a {
        justify-self: center;
    }

    .detailed-card-paragraph {
        font-size: 14px;
    }

    .slider-container {
        width: 110% !important;
        left: 40% !important;
    }
}