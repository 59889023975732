.footer-icons {
    transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
    margin: 0 8px !important;
}

.footer-icons:hover {
    transform: translateY(-3px);
    filter: brightness(0);
}

.desktop-side-element-container::after {
    content: "";
    display: block;
    width: 1px;
    height: 90px;
    margin: 0px auto;
    background-color: black;
}


.desktop-side-element-container {
    position: fixed;
    bottom: 0;
}

.desktop-side-element-container a {
    margin: 20px auto;
    padding: 10px;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.1em;
    -webkit-writing-mode: vertical-rl;
    writing-mode: vertical-rl;
    color: black;
}

.desktop-side-element-container a:hover {
    color: var(--primary) !important;
    font-weight: bold;
    transform: translateY(-3px);
}

.desktop-side-element-container.fade-in {
    animation: fade-element-in 1s ease-in-out;
    opacity: 1;
}

.desktop-side-element-container.fade-out {
    animation: fade-element-out 1s ease-in-out;
    opacity: 0;
}

@keyframes fade-element-in {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes fade-element-out {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

.desktop-side-element-icon-container {
    display: flex;
    flex-direction: column;
}

.desktop-side-element-icon-container:last-child {
    margin-bottom: 20px;
}

.desktop-side-element-icon-container-img {
    filter: brightness(0);
}

.desktop-side-element-icon-container-img:hover {
    filter: brightness(1) !important;
}

@media screen and (max-width: 768px) {
    .desktop-side-element-container {
        display: none;
    }
}
