.tab-container-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.tab-container-tabs {
    display: flex;
    flex-direction: column;
    padding-right: 20px;
    position: relative;
}

.tab-container-tab {
    padding: 10px 10px 20px 10px;
    cursor: pointer;
    font-size: 20px;
    position: relative;
    border-left: 2px solid var(--secondary-alpha);
    margin: 0 !important;
}

.tab-container-tab.active {
    background-color: #f2f2f2;
    width: 90%;
    color: var(--primary);
}

.tab-container-content {
    width: 100%;
    z-index: 300;
}

.tab-container-content h3 {
    margin-bottom: 5px;
}

h2,
p {
    margin: 0;
}

.tab-container-line {
    position: absolute;
    z-index: 200;
    left: -1.5px;
    top: 0;
    width: 5px !important;
    background-color: var(--primary);
    height: 25% !important;
    transition: transform 0.3s ease-in-out;
}

.tab-container-li {
    position: relative;
    padding-left: 30px;
    margin-bottom: 10px;
    font-size: 20px;
    top: -3px;
}

.tab-container-li2 {
    position: relative;
    padding-left: 30px;
    margin-bottom: 10px;
    font-size: 20px;
    top: -3px;
}

.tab-container-li::before {
    content: "✤";
    position: absolute;
    left: 0px;
    color: var(--primary);
}

.tab-container-li2::before {
    content: "➢";
    position: absolute;
    left: 0px;
    color: var(--primary);
}

.tab-container-ul {
    list-style: none;
}

@media (max-width: 768px) {
    .tab-container-container {
        flex-direction: column;
        height: unset;
        width: 100%;
        margin: 0 auto;
        text-align: center;
    }

    .tab-container-tabs {
        display: grid;
        grid-template-columns: 20% 20% 20% 20%;
        width: 100%;
        margin: 0;
        justify-content: inherit;
    }

    .tab-container-line {
        height: 3px !important;
        width: 27% !important;
    }

    .tab-container-tab {
        border-left: 0;
        border-top: 2px solid var(--secondary-alpha);
    }

    .tab-container-content {
        width: 100%;
    }
}