.quote-container {
    width: 75%;
    position: relative;
    margin: 4% auto;
}

.quote-container-quote-left {
    font-family: durk wide web;
    position: absolute;
    top: 6px;
    left: -33px;
    font-size: 6.2vw;
}

@media screen and (min-width: 1025px) {
    .quote-container-quote-left {
        top: -10px;
        font-size: 72px;
    }
}

.quote-container-blockquote {
    width: 60%;
    transform-origin: left center;
    transition: opacity .75s cubic-bezier(.25, .46, .45, .94), transform .75s cubic-bezier(.25, .46, .45, .94);
    line-height: 2.3em;
}

.quote-container-blockquote p {
    font-family: durk wide web;
    font-weight: bold;
    font-size: 30px;
}

.quote-container-blockquote.page-in {
    transform: rotateY(0) rotateZ(0) translateZ(0);
}

.quote-container-blockquote.page-out {
    transform: rotateY(-90deg) rotateZ(15deg) translateZ(0);
}

.quote-container-attribution {
    margin: 3% 0;
    font-weight: bold;
    transform-origin: left center;
    transition: opacity .75s cubic-bezier(.25, .46, .45, .94), transform .75s cubic-bezier(.25, .46, .45, .94);
    line-height: 2.3em;
}

.quote-container-attribution.page-in {
    transform: rotateY(0) rotateZ(0) translateZ(0);
}

.quote-container-attribution.page-out {
    transform: rotateY(-90deg) rotateZ(15deg) translateZ(0);
}
@media screen and (max-width: 768px) {
    .quote-container-blockquote {
        width: 100%;
    }
}