.card {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px;
    padding: 20px;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    opacity: 0;
    transform: translateX(-200px);
    transition: opacity 0.5s ease, transform 0.5s ease;
}

.card.animate-in {
    opacity: 1;
    transform: translateX(0);
    /*transition-delay: 0.5s;*/
    animation: slide-fade-in 1s ease-in-out;

}
.card.animate-out {
    opacity: 0;
    transform: translateX(-200px);
    animation: slide-fade-out 1s ease-in-out;
    /*transition-delay: 0.5s;*/
}
@keyframes slide-fade-in {
    0% {
        opacity: 0;
        transform: translateX(-200px);
    }
    100% {
        opacity: 1;
        transform: translateX(0);
    }
}
@keyframes slide-fade-out {
    0% {
        opacity: 1;
        transform: translateX(0);

    }
    100% {
        opacity: 0;
        transform: translateX(-200px);
    }
}

.card__image-container {
    height: 200px;
    overflow: hidden;
    border-radius: 10px 10px 0 0;
    margin-bottom: 20px;
}

.card__image {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
}

.card__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.card__title {
    margin: 0;
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
}

.card__text {
    margin: 0;
    font-size: 16px;
    margin-bottom: 20px;
}