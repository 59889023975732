
.animated-container {
    display: flex;
    opacity: 0;
    padding: 0 3%;
    transform: translateY(-50px);
    transition: opacity 0.5s ease, transform 0.5s ease;
}

.animated-container-btn {
    background: transparent;
    border: none;
    font-weight: bold;
    text-decoration: underline;
    cursor: pointer;
}

.animated-container.fade-up {
    opacity: 1;
    transform: translateY(0);
    animation: slide-fade-up 1s ease-in-out;

}

.animated-container.fade-out {
    opacity: 0;
    transform: translateY(-50px);
    animation: slide-fade-down 1s ease-in-out;
}

@keyframes slide-fade-up {
    0% {
        opacity: 0;
        transform: translateY(-50px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes slide-fade-down {
    0% {
        opacity: 1;
        transform: translateY(0);

    }
    100% {
        opacity: 0;
        transform: translateY(-50px);
    }
}

.animated-container-container {
    display: flex;
    width: 85%;
    margin: 5% auto;
}

.animated-container-container div {
    width: 100%;
    text-align: center;
}

@media screen and (max-width: 768px) {
    .animated-container-container {
        width: 90%;
        flex-direction: column;
    }

    .animated-container-container div {
        text-align: start;
    }
}