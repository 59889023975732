.carousel-arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: 1;
}

.carousel-arrow svg {
    width: 100%;
    height: 100%;
    fill: #666;
    transition: all 0.2s ease-in-out;
}

.carousel-arrow:hover svg {
    fill: #333;
}
.closeBtn{
    align-self: end;
    background: transparent;
    border: none;
    margin: 1% 1% 0 0;
    font-weight: bold;
    cursor: pointer;
}
.closeBtn:hover {
    opacity: 0.5;
}

@media screen and (max-width: 768px) {
    .react-3d-carousel {
        margin-top: 23%;
    }
}